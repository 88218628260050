import { app } from '@/firebaseConfig'
import { getStorage, ref as firebase_ref, uploadBytes, getDownloadURL, listAll, deleteObject, getMetadata } from "firebase/storage";
import { Timestamp } from 'firebase/firestore';
import Compressor from 'compressorjs'
const storage = getStorage(app);

// these functions manage the firebase storage


const uploadImage = async (blob, id, path='images/') => {
    var compressed = {}

    const max_file_size = 1_000_000
    var compression_ratio = Math.min(1, max_file_size / blob.size)

    new Compressor(blob, {
        convertSize: max_file_size,
        convertTypes: 'image/jpeg',
        quality: compression_ratio,
        async success(result) {
            compressed = result

            const imagesRef = firebase_ref(storage, path + id);

            return await uploadBytes(imagesRef, result).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });
        },
        error(err) {
            console.log(err.message);
        },
    },)
    

}

const uploadPdf = async (blob, id, path='pdfs/') => {
    const pdfRef = firebase_ref(storage,path + id);

    return await uploadBytes(pdfRef, blob).then((snapshot) => {
        console.log('Uploaded a blob or file!');
    });
}

const loadImageUrl = async (id, path='images/') => {
    return await getDownloadURL(firebase_ref(storage, path + id))
    .catch((error) => {
        console.log(error)
    });
}

const loadPdfUrl = async (id, path='pdfs/') => {
    return await getDownloadURL(firebase_ref(storage, path + id))
    .catch((error) => {
        console.log(error)
    }).then(e => e);
}
const getAllImageIds = async (path='images/') => {
    // Create a reference under which you want to list
    const listRef = firebase_ref(storage, path);

    // Find all the prefixes and items.
    return await listAll(listRef)
        .then(async (res) => {
            const { items } = res;
            const urls = await Promise.all(
                items.map(async (item) => {
                    const metadata = await getMetadata(item)
                    const updated_time = Date.parse(metadata.updated)
                    return {'link': await getDownloadURL(item), 'id': item.name, 'updated': updated_time, 'path': path}
                })
            );

            // Array of download URLs of all files in that folder
            return urls
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
}

const getAllPdfIds = async (path) => {
    // Create a reference under which you want to list
    const listRef = firebase_ref(storage, path);

    var urls = []
    // Find all the prefixes and items.
    await listAll(listRef)
        .then(async (res) => {
            const { items } = res;
            urls = await Promise.all(
                items.map(async (item) => {
                    const metadata = await getMetadata(item)
                    const updated_time = Date.parse(metadata.updated)
                    return {'link': await getDownloadURL(item), 'id': item.name, 'updated': updated_time}
                })
            );

            // Array of download URLs of all files in that folder
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    return urls
}

const deleteImageFile =  async (id, path="images/") => {
    deleteObject(firebase_ref(storage, path + id))
}

const deletePdfFile =  async (id, path="pdfs/") => {
    deleteObject(firebase_ref(storage, path + id))
}


export {
    uploadImage,
    loadImageUrl,
    getAllImageIds,
    getAllPdfIds,
    deleteImageFile,
    deletePdfFile,
    uploadPdf,
    loadPdfUrl
} 