import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '@/views/AdminView'

const routes = [

    {
        path: '/admin',
        name: 'admin',
        component: AdminView
    },
    {   
        path: '/:pathMatch(.*)*', 
        name: 'Home', 
        component: HomeView 
    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
