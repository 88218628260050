
import {textify, getMarkTexWarnings} from "@/textify"
import { v4 } from 'uuid';
const uuidv4 = v4


const formatTime = (hours, minutes) => {
    hours = hours % 24;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes
    return strTime;
}

function randomId(){
    return uuidv4()
 }

const minTime = (time_obj) => {
    var total_time = 0

    if (!isNaN(Number(time_obj.hours))) {
        total_time += 60 * Number(time_obj.hours)

    }
    if (!isNaN(Number(time_obj.minutes))) {
        total_time += Number(time_obj.minutes)
    }
    
    return total_time
     
}
const minToTime = (min) => {
    return formatTime(Math.floor(min / 60), min - Math.floor(min/60) * 60)
}


const objDeepEqual = (v1, v2) => {
    if (v1 === v2) return true
    if (v1 == null || v2 == null || typeof v1 != "object" || typeof v2 != "object") return false
    
    let vlkeys = Object.keys(v1)
    let v2keys = Object.keys(v2)
    
    if (vlkeys.length != v2keys.length) return false
    
    for (let key of vlkeys) {
        if (!v2keys.includes(key) || !isDeepEqual(v1 [key], v2 [key])) return false
    }
    return true
    
}

const arrDeepEqual = (arr1, arr2) => {
    if (arr1 === arr2) return true
    if (arr1.length != arr2.length) return false
    var is_equal = true
    var used_indecies = []
    for (const index1 in arr1) {
        const element1 = arr1[index1]
        const index = arr2.findIndex((element2, index2) => {
            if (used_indecies.includes(index2)){
                return false
            }
            return isDeepEqual(element1, element2)
        })
        if (index == -1){
            is_equal = false
            break
        }
    };
    return is_equal 
}

const isDeepEqual = (v1, v2) => {
    if (v1 === v2) return true
    if (typeof v1 != typeof v2){
        return false
    }
    if (v1 instanceof Array){
        return arrDeepEqual(v1, v2)
    }
    if (typeof v1 == 'object'){
        return objDeepEqual(v1, v2)
    }
    return _.isEqual(v1, v2)
}

const fixWeirdLatex = (text) => {
    // return text
    if(!text || text == ""){return text}
    if(!text.includes("$")){return text}
    let re = /(\$\$.+?\$\$)([^\$])/gi;
    let output = text.replace(re,"$1\n$2")
    return output

}



export {
    formatTime,
    minTime,
    minToTime,
    isDeepEqual,
    getMarkTexWarnings,
    fixWeirdLatex,
    randomId
} 