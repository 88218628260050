import { collection, doc, setDoc, getDoc, query, getDocs, deleteDoc, onSnapshot } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import { app } from "./firebaseConfig";
import { randomId } from "./helpers";
import { ref, onUnmounted } from 'vue'

// these functions manage the firestore

const db = getFirestore(app);

const setDocData = async (collection_name, uid, data) => {
    setDoc(doc(db, collection_name, uid), data, {merge: true});
}

const getDocData = async (collection_name, uid) => {
    const docRef = doc(db, collection_name, uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
    // docSnap.data() will be undefined in this case
        return false
    }
}


const getFullData = (type, callback_func) => {
    if (!callback_func){
        callback_func = () => {}
    } 
    const close = onSnapshot(collection(db, type), snapshot => {
        callback_func(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}) ))
    })
    onUnmounted(close)
}

const deleteDocData = async (collection_name, uid) => {
    // await setDocData('deleted_' + collection_name, uid, await getDocData(collection_name, uid))
    const docRef = doc(db, collection_name, uid);
    await deleteDoc(docRef)
}

const getCollection = async (collection_name) => {
    const q  = query(collection(db, collection_name))
    const my_collection = await getDocs(q).then((querySnapshot) => {
        const tempDoc = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
        })
        return tempDoc
    })
    return my_collection
}

const updateCollection = async (collection_name, data, deleted_ids=[]) => {

    
    const new_data = data.reduce((new_data, item) => {
        if (!item.id){
            item.id = randomId(10)
        }

        new_data[item.id] = item
        delete new_data[item.id].id
        return new_data
    }, {})
    
    const q  = query(collection(db, collection_name))
    const mycollection = await getDocs(q)
    const collection_docs = mycollection.docs

    await Promise.all(Object.keys(new_data).map(async key=> {
        return setDocData(collection_name, key, new_data[key])
    }))

    await Promise.all(deleted_ids.map(async id => {

        return deleteDocData(collection_name, id)

    }))
}

export {
    getDocData,
    setDocData,
    deleteDocData,
    getCollection,
    updateCollection,
    getFullData
}