
  // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = { 
    apiKey : "AIzaSyDmDspRPMBn3vNxOKlJsajPmdvlv0PjAxA" , 
    authDomain : "artistieke-interlyceale.firebaseapp.com" , 
    projectId : "artistieke-interlyceale" , 
    storageBucket : "artistieke-interlyceale.appspot.com" , 
    messagingSenderId : "1021660095825" , 
    appId : "1:1021660095825:web:96214f1587c851ed645f70" 
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// const analytics = getAnalytics(app);
// we only have 1 account
const signInEmailPassword = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return userCredential.user
            // Signed in 
            const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}

export { 
    app, 
    auth, 
    signInEmailPassword
};


