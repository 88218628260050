import { defineStore } from 'pinia'
import {ref, onMounted,reactive,toRaw} from 'vue'
import { createData, deleteData, updateData, randomId } from '@/firebase.js'

import { getCollection, updateCollection, getDocData, setDocData, getFullData } from '@/firestore'
import { isDeepEqual, arrDeepEqual, mergeObjects } from '@/helpers'
import { loadImageUrl, loadPdfUrl } from '@/firebaseStorage'


import _ from 'lodash'

export const useDataStore = defineStore('data', {
    state: () => ({
        performances: [],
        jury: [],
        papers: [],
        jury_performances: [],
        announcements: [],

        images: {},

        deleted_jury_ids: [],
        deleted_performance_ids: [],
        deleted_paper_ids: [],
        deleted_jury_performance_ids: [],
        deleted_announcement_ids: [],


        local_performances: [],
        local_jury_performances: [],
        local_jury: [],
        local_papers: [],
        local_announcements: [],

        locations: [
            'Het Amsterdams lyceum',
            'Sporthallen Zuid',
            'Swift',
            'Olympisch stadion',
            'Arsenal',
            ' '
        ]
    }),
    getters: {
        // getLocalStorageChanges () {
        //     return {
        //         performances: !_.isEqual(this.performances, this.local_performances),
        //         jury_performances: !_.isEqual(this.jury_performances, this.local_jury_performances),
        //         jury: !_.isEqual(this.jury, this.local_jury),
        //         papers: !_.isEqual(this.papers, this.local_papers),
        //         announcements: !_.isEqual(this.announcements, this.local_announcements),
        //     }
        // }
    },
    actions: {
        setLocalStorage() {
            this.local_performances = structuredClone(this.performances)
            this.local_jury = structuredClone(this.jury)
            this.local_papers = structuredClone(this.papers)
            this.local_jury_performances = structuredClone(this.jury_performances)
            this.local_announcements = structuredClone(this.announcements)
        },
        getLocalStorageChanges () {
            return {
                performances: !_.isEqual(this.performances, this.local_performances),
                jury_performances: !_.isEqual(this.jury_performances, this.local_jury_performances),
                jury: !_.isEqual(this.jury, this.local_jury),
                papers: !_.isEqual(this.papers, this.local_papers),
                announcements: !_.isEqual(this.announcements, this.local_announcements),
            }
        },

        getRemovedIds(arr1, arr2){
            return arr2.filter(x => !arr1.includes(x))
        },

        async loadData(){
            getFullData('performances',(data) => {
                this.performances = data
            })
            getFullData('jury',(data) => {
                this.jury = data
            })
            getFullData('jury_performances',(data) => {
                this.jury_performances = data
            })
            getFullData('papers',(data) => {
                this.papers = data
            })
            getFullData('announcements',(data) => {
                this.announcements = data
            })
        },

        async updateFromLocal () {
            const data_types = [
                {
                    base_name:'jury', 
                    local_name:'local_jury',
                    deleted_ids:'deleted_jury_ids'
                },
                {
                    base_name: 'performances', 
                    local_name:'local_performances',
                    deleted_ids:'deleted_performance_ids'
                },
                {
                    base_name:'papers', 
                    local_name:'local_papers',
                    deleted_ids:'deleted_paper_ids'
                },
                {
                    base_name:'jury_performances', 
                    local_name:'local_jury_performances',
                    deleted_ids:'deleted_jury_performance_ids'
                },
                {
                    base_name:'announcements', 
                    local_name:'local_announcements',
                    deleted_ids:'deleted_announcement_ids'
                },
            ]

            
            const requests = data_types.map(type => {
                const updated_items = this[type.local_name].filter(item => {
                    const index = this[type.base_name].findIndex(e => e.id == item.id)
                    if (index == -1){
                        return true
                    }
                    return !isDeepEqual(item, this[type.base_name][index])
                })
                const delete_ids = structuredClone(this[type.deleted_ids])
                this[type.deleted_ids] = []
                return updateCollection(type.base_name, updated_items, delete_ids)
                

            })



            await Promise.all(requests)
        },
        async submitAdminEdit(){
            await this.updateFromLocal()
            // await this.loadData()
            this.setLocalStorage()
            this.new_images = []
        },




        async loadImageIds(images){
            // console.log('hi')

            await Promise.all(images.map(async image => {
                await loadImageUrl(image.id, image.path).then(url => this.images[image.path + image.id] = {link: url, ...this.images[image.path + image.id]})
            }))
        },
        async loadPdfIds(pdfs){
            await Promise.all(pdfs.map(async data => {
                await loadPdfUrl(data.id, data.path).then(url => this.pdfs[data.path+data.id] = {link: url, ...this.pdfs[data.path+data.id]})
            }))
        },
        async getImageUrl(id, path){

            if (this.images[path+id]){
                return this.images[path+id].link
            }
            await this.loadImageIds([{id:id, path:path}])
            // console.log(this.images)
            return this.images[path+id].link
        }
    }
})
