import firebase from 'firebase/compat/app';
import { getStorage, ref as firebase_ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import 'firebase/compat/firestore';
import { doc, updateDoc, setDoc, onSnapshot, getDoc, deleteDoc   } from "firebase/firestore";
import { ref, onUnmounted } from 'vue'

const config = { 
  apiKey : "AIzaSyDmDspRPMBn3vNxOKlJsajPmdvlv0PjAxA" , 
  authDomain : "artistieke-interlyceale.firebaseapp.com" , 
  projectId : "artistieke-interlyceale" , 
  storageBucket : "artistieke-interlyceale.appspot.com" , 
  messagingSenderId : "1021660095825" , 
  appId : "1:1021660095825:web:96214f1587c851ed645f70" 
};

const firebaseApp = firebase.initializeApp(config)

const db = firebaseApp.firestore()
const storage = getStorage(firebaseApp);

const uploadImage = (blob, id) => {
    const imagesRef = firebase_ref(storage, 'images/' + id);

    return uploadBytes(imagesRef, blob).then((snapshot) => {
        console.log('Uploaded a blob or file!');
    });
}
const getImageUrl = async (id) => {
    return await getDownloadURL(firebase_ref(storage, 'images/' + id))
    .catch((error) => {
        console.log(error)
    });
}
const getAllImageIds = async () => {
    // Create a reference under which you want to list
    const listRef = firebase_ref(storage, 'images');

    // Find all the prefixes and items.
    return await listAll(listRef)
        .then(async (res) => {
            const { items } = res;
            const urls = await Promise.all(
                items.map(async (item) => {return {'link': await getDownloadURL(item), 'id': item.name}})
            );

            // Array of download URLs of all files in that folder
            return urls
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
}
const deleteFile =  async (id) => {
    deleteObject(firebase_ref(storage, 'images/' + id))
}



const auth = getAuth(firebaseApp)

const signInEmailPassword = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return userCredential.user
            // Signed in 
            const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}





const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};




const createData = (route, data) => {
    setDoc(doc(db, route, randomId(10)), data)
}
const getData = async (type, id) => {
    const data = await getDoc(doc(db, type, id))

    return data.exists ? data.data() : null
} 
const deleteData = async (type, id) => {
    await deleteDoc(doc(db, type, id), (result) => {console.log(result)})
}

const updateData = async ({type, id=null, data}) => {
    if (!['', null].includes(id)) {
        await setDoc(doc(db, type, id), data)

    } else {
        var new_data = {}
        console.log(data)
        data.forEach(async e => {
            const mydoc = doc(db, type, e.id)


            console.log(e)
            const response = await setDoc(mydoc, e)

            
        })
    }
}

const getFullData = (type, callback_func) => {
    if (!callback_func){
        callback_func = () => {}
    } 
    const close = onSnapshot(db.collection(type), snapshot => {
        callback_func(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}) ))
    })
    // onUnmounted(close)
}

export {
    db,
    getData,
    deleteData,
    createData,
    updateData,
    getFullData,
    randomId,
    uploadImage,
    getImageUrl,
    getAllImageIds,
    deleteFile,
    signInEmailPassword
} 